@import url("https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
.header-topbarSection {
    height: 71px !important;
    background-color: #0F1820;
    margin-top: 0px !important;
    /* margin-left: 60px !important; */
}

/* .header-branding img {
    height: 49px !important;
    margin-left: 60px !important;
} */

.header-container{
    max-width: 1440px !important;
}

.page-container {
    display: flex; /* Utilizamos flexbox para expandir el contenido */
    justify-content: center; /* Centramos el contenido horizontalmente */
  }

  .marquee-container {
    max-width: 100%; /* Limitamos el ancho máximo al ancho de la página */
    overflow: hidden; /* Ocultamos cualquier contenido que se desborde */
  }

  .marquee {
    white-space: nowrap; /* Evita que el texto se divida */
    animation: marquee 10s linear infinite; /* Animación Marquee */
  }

  .marquee span {
    display: inline-block; /* Para que el texto se muestre en línea */
    padding-right: 20px; /* Espacio opcional entre repeticiones */
  }

  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }